<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="8">
          <b-card-group>
            <b-card v-if="isPageLoading" no-body class="p-4">
              <b-card-body>
                <div class="text-center">
                  <i class="fa fa-cog fa-spin fa-4x"></i>
                </div>
              </b-card-body>
            </b-card>
            <b-card v-if="!isPageLoading" no-body class="p-4">
              <b-card-body>
                <b-form>
                  <h1>Вхід в систему</h1>
                  <p class="text-muted">Увійдіть, щоб отримати доступ</p>
                  <b-input-group class="mb-3">
                    <b-input-group-prepend>
                      <b-input-group-text
                        ><i class="icon-user"></i
                      ></b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      type="text"
                      class="form-control"
                      placeholder="Email"
                      autocomplete="Email"
                      v-model="loginParams.email"
                    />
                  </b-input-group>
                  <b-input-group class="mb-4">
                    <b-input-group-prepend>
                      <b-input-group-text
                        ><i class="icon-lock"></i
                      ></b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      type="password"
                      class="form-control"
                      placeholder="Password"
                      autocomplete="current-password"
                      v-model="loginParams.password"
                      @keyup.enter="doLogin()"
                    />
                  </b-input-group>
                  <b-row>
                    <b-col cols="6">
                      <b-button
                        variant="primary"
                        @click="doLogin()"
                        class="px-4"
                      >
                        Увійти
                      </b-button>
                    </b-col>
                    <b-col cols="6" class="text-right">
                      <b-button
                        variant="link"
                        class="px-0"
                        @click="$router.push('restore')"
                        >Забули пароль?
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
            <b-card
              v-if="!isPageLoading"
              no-body
              class="text-white bg-primary py-5"
            >
              <b-card-body class="text-center">
                <div>
                  <h2>Немає облікового запису?</h2>
                  <p>
                    Зареєструйтесь зараз, щоб мати можливість доступу до
                    функцій системи
                  </p>
                  <router-link :to="{ name: 'Register' }">
                    <b-button variant="primary" class="active mt-3"
                      >Зареєструватись
                    </b-button>
                  </router-link>
                </div>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import API from "../api/stalk.net";

export default {
  name: "Login",
  data() {
    return {
      loginParams: {
        email: "",
        password: ""
      },
      isPageLoading: false
    };
  },
  methods: {
    doLogin() {
      let self = this;

      self.isPageLoading = true;
      API.private.logout();
      API.login(self.loginParams)
        .then(() => {
          if (localStorage.getItem("user_group") === "special") {
            this.$router.replace("system/special/jupiter");
          } else {
            this.$router.replace("system");
          }
          self.isPageLoading = false;
        })
        .catch(e => {
          console.error(e);
          self.showLoginError({ message: e.response });
          self.isPageLoading = false;
        });
    }
  },
  notifications: {
    showLoginError: {
      title: "Помилка логіну",
      type: "error" // You also can use 'VueNotifications.types.error' instead of 'error'
    }
  }
};
</script>

<style scoped>
@media (max-width: 768px) {
  .card-group {
    display: flex;
    flex-direction: column;
  }
}
</style>
